import logo from './logo.svg';
import './App.css';
import { useEffect, useState } from 'react';
import axios from 'axios';

function App() {
  const [data, setData] = useState(null);
  const [name, setName] = useState('Gideon Odiase');
  const [age, setAge] = useState(20);
  const [bio, setBio] = useState("Hi, I'm Gideon, a Software Engineer and a passionate fan of the beautiful game with @ChelseaFc. I'm a husband and father to a wonderful baby boy! ")

  useEffect(() => {
    axios.get('http://localhost:8090/details')
      .then(async(res) => {
        if(!data) {
          const result = res.data.data;
          setData(result);
          setName(result.name);
          setAge(result.age);
          setBio(result.bio);
        }
      })
      .catch((err) => console.log(err, "error"));
  });

  return (
    <div className="App">
      <div className='main'>
        <h1>{name}</h1>
        <br/>
        <br/>
         Here is {name}, he is {age} years old.
         <div
          className='bio'
         >
          {bio}
         </div>
      </div>
    </div>
  );
}

export default App;
